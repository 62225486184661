@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova-Regular.eot');
  src: url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ProximaNova-Regular.woff') format('woff'),
    url('./fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova-Semibold.eot');
  src: url('./fonts/ProximaNova-Semibold.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/ProximaNova-Semibold.woff') format('woff'),
    url('./fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: content-box;
}

a {
  color: black;
}

textarea.ant-input {
  max-width: 100%;
  height: 165px;
}

.ant-table-thead > tr > th {
  font-weight: 750;
}

.ant-form-item-required::before {
  display: none !important;
}

.ant-form-item-required::after {
  display: inline-block !important;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;

  line-height: 1;
  content: '*' !important;
}
.ant-select-selection-placeholder {
  color: #6b7a87;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.ant-tabs-tab {
  color: #6b7a87 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #102f54 !important;
  font-weight: 600;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #2682de;
  pointer-events: none;
}
